import profilePhotoUrl from '~/helpers/profilePhotoUrl'
import moment from 'moment'
import getUser from "@/helpers/getUser";

export default {
  filters: {
    localTime (time) {
      return moment.utc(time).local().calendar(null, {
        sameDay: 'h:mm A',
      })
    },
  },

  methods: {
    conversationPhoto (conversation) {
      // // if user was the last messenger, show the first other participant
      // if (conversation.last_message.participant_id === conversation.current_participant.participant_id) {
      //   return profilePhotoUrl(conversation.participants
      //     .find(participant => participant.participant_id !== conversation.current_participant.participant_id)
      //     .member
      //     .profile_photo)
      // }
      // // else, show the photo of the last messenger
      // return profilePhotoUrl(conversation.last_message.participant.member)
      console.log(getUser());

      return profilePhotoUrl(conversation.participants
          .find(participant => participant.participant_id !== conversation.current_participant.participant_id)
          .member)
    },
  },
}
