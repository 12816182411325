<template>
  <div>
    <!-- Show when there are contacts-->
    <div v-if="contacts.length > 0" class="p-3">
      <p class="bold mb-2 gray-text">Your contacts with "{{ search }}"</p>
      <hr class="gray-hr">
    </div>
    <div v-for="c in contacts" :key="c.index">
      <router-link v-if="c.conversation.last_message" :to="`/conversations/${c.conversation_id}`" class="no-underline-hover message-row" exact>
        <el-row :gutter="15" type="flex">
          <el-col :xs="4" :sm="3" :md="2">
            <img :src="conversationPhoto(c.conversation)" alt="m.conversation.name" class="rounded-circle w-100">
          </el-col>
          <el-col :xs="20" :sm="21" :md="22" :class="{ bold: c.conversation.current_participant.num_unread_messages }" class="align-self-center">
            <p class="small mb-0">
              <span v-text="c.conversation.name" />
            </p>
          </el-col>
        </el-row>
      </router-link>
    </div>
    <div v-show="messages.length > 0 || searching" class="p-3 message-search">
      <p class="bold mb-2 gray-text">Your messages with "{{ search }}"</p>
      <hr class="gray-hr">
    </div>
    <div v-show="messages.length == 0 && ! searching" class="p-3 message-search">
      <p class="bold mb-2 gray-text">No messages with "{{ search }}"</p>
    </div>
    <div>
      <router-link v-for="m in messages" :key="m.message_id" :to="`/conversations/${m.conversation_id}`" class="no-underline-hover message-row" exact>
        <el-row :gutter="15" type="flex">
          <el-col :xs="4" :sm="3" :md="2">
            <img :src="conversationPhoto(m.conversation)" :alt="m.conversation.name" class="rounded-circle w-100">
          </el-col>
          <el-col :xs="20" :sm="21" :md="22" class="align-self-center">
            <p class="small mb-0">
              <span v-text="m.conversation.name" />
              <span class="gray-text ml-2">{{ m.created_at | localTime }}</span>
            </p>
            <p class="small mb-0" v-text="m.message" />
          </el-col>
        </el-row>
      </router-link>
      <infinite-loading direction="top" @infinite="getMoreMessages">
        <div slot="no-more">&nbsp;</div>
        <div slot="no-results">&nbsp;</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import Message from '~/models/Message'
import Conversation from '~/models/Conversation'
import showsConversations from '~/mixins/showsConversations'

export default {
  components: { InfiniteLoading },

  mixins: [showsConversations],

  props: {
    search: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      contacts: [],
      messages: [],
      searching: false,
      page: 1,
    }
  },

  watch: {
    search () {
      this.page = 1
      this.fetch()
    },
  },

  methods: {
    fetch: _.debounce(function () {
      const search = this.search
      this.searching = true
      Conversation.search({ search }).then(response => {
        this.contacts = response.data
      })
      Message.search({ search, page: this.page }).then(response => {
        this.searching = false
        this.messages = response.data.data
      })
    }, 800),

    getMoreMessages ($state) {
      Message.search({ search: this.search, page: ++this.page }).then(response => {
        // if there are no more new ones
        if (response.data.data.length === 0) {
          return $state.complete()
        }

        // add to the list of messages if it doesn't exist in the message list yet
        this.messages = this.messages.concat(response.data.data.filter(message => this.messages.findIndex(m => m.message_id === message.message_id) < 0))

        // update state to loaded
        $state.loaded()
      })
    },
  },
}
</script>

<style scoped>
  .message-highlight {
    background: #fdeda3;
  }
</style>
