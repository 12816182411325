import Model from '~/models/Model'
import getUserType from '~/helpers/getUserType'
import { API_BASE_URL } from '~/vuex/utils/constant'

export default class Message extends Model {
  static get resource () {
    return 'messages'
  }

  static baseURL (path = '') {
    return API_BASE_URL + '/messenger/' + getUserType() + '/messages/' + path
  }
}
