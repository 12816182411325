<template>
  <dashboard>
    <div class="jobfeed">
      <!-- <el-card  class="">
        <el-row :gutter="20" type="flex" justify="center" class="xs-block" v-if="beta">
          <el-col :xs="24" :sm="18" :md="14" class="align-self-center">
            <h4 class="bold">This feature is coming soon</h4>
            <hr class="w-25 mt-3 mb-3">
            <p class="p-0">You will be able to send and receive messages from future employers or clients once we are fully launched. Stay tuned!</p>
          </el-col>
          <el-col :xs="24" :sm="16" :md="6">
            <img src="/images/comingsoon.png" style="width: 100%;" class="xs-block-img">
          </el-col>
        </el-row>
      </el-card> -->
      <el-row v-if="!beta" :gutter="15">
        <el-col :xs="24" :md="24">
          <el-card class="message-page">
            <h4 class="ml-3 mb-3 mt-3 bold orange-text">Messages</h4>
            <hr>
            <div v-if="conversations.length > 0" class="search">
              <el-input v-model="search" placeholder="Search contacts and messages">
                <el-button slot="append" class="search-orange" icon="el-icon-search"> Search</el-button>
              </el-input>
            </div>
            <div v-show="! search">
              <!-- Show when there are contacts-->
              <div v-if="conversations.length > 0" class="p-3">
                <p class="bold mb-2 gray-text">Your contacts</p>
                <hr class="gray-hr">
              </div>
              <div v-for="c in conversations" :key="c.index">
                <router-link v-if="c.last_message" :to="`/conversations/${c.conversation_id}`" class="no-underline-hover message-row" exact>
                  <el-row :gutter="15" type="flex">
                    <el-col :xs="4" :sm="3" :md="2">
                      <img :src="conversationPhoto(c)" alt="..." class="rounded-circle w-100">
                    </el-col>
                    <el-col :xs="20" :sm="21" :md="22" :class="{ bold: c.current_participant.num_unread_messages }" class="align-self-center">
                      <p class="small mb-0">
                        <span v-text="c.name" />
                        <span class="gray-text ml-2">{{ c.last_message.created_at | localTime }}</span>
                        <span v-if="c.current_participant.num_unread_messages" class="badge badge-danger badge-pill ml-1" v-text="c.current_participant.num_unread_messages" />
                      </p>
                      <p class="small mb-0">
                        {{ c.last_message.message }}
                      </p>
                    </el-col>
                  </el-row>
                </router-link>
              </div>
            </div>
            <search-results v-show="search" :search="search" />
          </el-card>
          <!-- Should only show up when there are no conversations -->
          <el-card v-if="conversations.length === 0">
            <div class="text-center mt-3 mb-3">
              <center v-if="userType === 'employer'">
                <p class="small">Your message page looks empty. Start messaging freelancers!</p>
                <router-link to="/employer/freelancers">
                  <el-button type="success">Check our list of freelancers</el-button>
                </router-link>
              </center>
              <center v-if="userType === 'freelancer'">
                <p class="small">You don't have any messages. Update your profile to increase your chances of being contacted.</p>
                <div class="text-center pb-2">
                  <el-button type="warning" @click="$router.push('/accountdetails')">Update Your Profile</el-button>
                </div>
              </center>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import SearchResults from '~/components/Messenger/SearchResults'
import { mapState } from 'vuex'
import showsConversations from '~/mixins/showsConversations'
import getUserType from '~/helpers/getUserType'

export default {
  name: 'ConversationList',

  components: { Dashboard, SearchResults },

  mixins: [showsConversations],

  data () {
    return {
      beta: false,
      search: '',
    }
  },

  computed: {
    ...mapState('messenger', ['conversations']),

    userType () {
      return getUserType()
    },
  },

  created () {
    this.$store.dispatch('messenger/fetchConversations')
  },

  methods: {
    indexMethod (index) {
      return index * 2
    },
  },
}
</script>

<style lang="scss">
  .gray-hr{
    border-top: 1px solid lightgray !important;
  }
  .message-highlight {
    background: #fdeda3;
  }
  .bold p {
    font-weight: bold;
  }
  .el-table__row {
    cursor: pointer;
  }
  .jobfeed .message-page {
    .el-card {
      border: none;
    }
    .el-card__body {
      padding: 0;
    }
    .header {
      background-color: #E87722;
      padding: 15px;
    }
    .header h5 {
      font-weight: bold;
      font-size: 1rem;
      color: white;
      margin: 0;
    }
    .message-row {
      padding: 15px;
      border-bottom: 1px solid #f6f6f6;
      display: block;
    }
    .message-row:last-child {
      padding: 15px 15px 15px 15px;
      border-bottom: 0;
      display: block;
    }
    .message-row:hover {
      background-color: #f6f6f6;
    }
    .el-table-column--selection .cell {
      padding-left: 25px;
    }
    .el-checkbox {
      margin-bottom: 0;
    }
    .search {
      padding: 25px 15px;
    }
    .search .el-input-group__append {
      background: #E87722;
      color: white;
    }
    .search .el-input-group__append:focus, .search .el-input-group__append:active, .search button {
      outline: none;
    }

  }
  @media (max-width: 767px) {
    .xs-block {
      display: block;
    }
    .xs-block-img {
      display: block;
      margin: 15px auto;
      max-width: 400px;
    }
  }
</style>
